import React, { useState, useEffect, useRef } from "react";
import "./App.css"; // F�ge deine CSS-Datei hinzu

function App() {
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const [title, setTitle] = useState(""); // State f�r Titel
  const [duration, setDuration] = useState(""); // State f�r Dauer
  const audioRef = useRef(null); // Referenz zum Audio-Element

  const reloadPage = () => {
    console.log("Reloading page in 1 second...");
    setTimeout(() => {
      window.location.href = window.location.href;
    }, 1000);
  };

  const handleDownload = async () => {
    setMessage("Downloading...");
    setDownloadLink("");
    setTitle("");
    setDuration("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/download`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        setMessage(`Error during download: ${errorText}`);
        reloadPage(); // Seite nach Download-Fehler neu laden
        return;
      }

      const data = await response.json();
      if (response.status === 200) {
        setMessage("Download successful");
        const filename = data.file;
        if (!filename) {
          setMessage("Error: Could not retrieve filename");
          reloadPage(); // Seite neu laden, wenn Dateiname fehlt
          return;
        }
        setDownloadLink(`${process.env.REACT_APP_API_URL}/downloads/${filename}`);
        setTitle(filename.replace(".mp3", ""));
      } else {
        setMessage(data.message || "Error occurred during download");
      }
    } catch (error) {
      setMessage("Error downloading video");
    }
  };

  const fetchLastFile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/last-file`
      );
      if (!response.ok) {
        const errorText = await response.text();
        setMessage(`Error fetching last file: ${errorText}`);
        return; // Kein Reload der Seite bei diesem Fehler
      }

      const data = await response.json();
      const filename = data.file;
      setDownloadLink(`${process.env.REACT_APP_API_URL}/downloads/${filename}`);
      setTitle(filename.replace(".mp3", ""));
    } catch (error) {
      console.error("Error fetching last file:", error);
      setMessage("Error fetching last file");
    }
  };

  useEffect(() => {
    fetchLastFile();
  }, []);

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      const durationInSeconds = Math.floor(audioRef.current.duration);
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = durationInSeconds % 60;
      setDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
    }
  };

  return (
    <div className="app-container">
      <div className="card">
        <h1>YouTube to MP3 Converter</h1>
        <input
          className="input-field"
          type="text"
          placeholder="Enter YouTube URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button className="download-button" onClick={handleDownload}>
          Starte Download
        </button>
        <p>{message}</p>

        {downloadLink && (
          <div>
            <h2>{title}</h2> {/* Titel anzeigen */}
            <a href={downloadLink} download className="download-link">
              Download MP3
            </a>
            <audio
              ref={audioRef}
              onLoadedMetadata={handleLoadedMetadata}
              style={{ display: "none" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
